const getRootUrl = (stage) => {
    if (stage === "dev") return "https://telehealth-api-dev.netcaretech.co.za";
    else if (stage === "prod") return "https://telehealth-api-prod.netcaretech.co.za";

    // Default
    return "http://localhost:8888";
};

const getVCSlotBookingRootUrl = (stage) => {
    if (stage === "dev") return "https://virtualcare-v2-api-dev.netcare.digital";
    // else if (stage === "staging") return "virtualcare-v2-api-staging.netcare.digital";
    else if (stage === "prod") return "https://virtualcare-v2-api.netcare.digital";

    // Default
    return "http://localhost:8000";
};

const getVCSlotBookingV2RootUrl = (stage) => {

};

const getVideoUrl = (stage) => {
    if (stage === "dev") return "https://video-dev.netcaretech.co.za";
    else if (stage === "prod") return "https://video.netcaretech.co.za";

    // Default
    return "http://localhost:8888";
};

const getAuthClientID = (stage) => {
    if (stage === "dev") return "SK1C68DR2obBuU4Rs7jp5Mlf0CS3SH5i";
    else if (stage === "prod") return "zB4gz5vT2zeFI5P42zfZGafUc4yWyWVK";
    return "";
};

const getAuthAudience = (stage) => ("https://telehealth.netcaretech.co.za");


export const STAGE = process.env.REACT_APP_STAGE;
export const API_ROOT_URL = getRootUrl(STAGE);
export const API_VCSLOTBOOKING_URL = getVCSlotBookingRootUrl(STAGE);
export const AUTH_URL = "https://auth.netcaretech.co.za";
export const AUTH_CLIENT_ID = getAuthClientID(STAGE);
export const AUTH_AUDIENCE = getAuthAudience(STAGE);
export const AUTH_CONNECTION = "Username-Password-Authentication";
export const VIDEO_URL = getVideoUrl(STAGE);
export const GROUP_VIDEO_URL = getVideoUrl(STAGE).replace('video', 'consult');