import {apiRequest, API_GET, API_POST, API_DELETE} from "../../actions/api";
import {
    FETCH_BOOKINGS,
    PERFORM_BOOKING_ACTION,
    MAKE_BOOKING,
    FETCH_CONSULTS,
    setBookings,
    fetchBookings,
    setBookingsLoading,
    setAlert,
    clearBookingsForm,
    setBookingsRequestTimestamp,
    FETCH_BOOKING,
    setBooking,
    FETCH_SURVEY_QUESTIONS,
    setSurveyQuestions,
    SAVE_BOOKING_ATTRIBUTES,
    FETCH_PRACTITIONER_AVAILABILITY,
    setPractitionerAvailability,
    MAKE_CALENDAR_BOOKING,
    MAKE_INSTANT_BOOKING,
    FETCH_LATEST_INSTANT_BOOKINGS,
    setInstantBookings,
    fetchLatestInstantBookings,
    clearPractitionerAvailability,
    CANCEL_CALENDAR_BOOKING,
    RESCHEDULE_BOOKING,
    RESCHEDULE_CALENDAR_BOOKING
} from "../../actions/bookings";
import {push, replace} from "../core/router";
import {API_VCSLOTBOOKING_URL} from "../../consts";
import {text2HTML} from "../../../views/Booking/bookingUtils";


const bookings = ({dispatch, getState}) => next => action => {

    next(action);

    if (!([FETCH_BOOKINGS, PERFORM_BOOKING_ACTION, MAKE_BOOKING, FETCH_CONSULTS, FETCH_BOOKING, FETCH_SURVEY_QUESTIONS,
        SAVE_BOOKING_ATTRIBUTES, FETCH_PRACTITIONER_AVAILABILITY, MAKE_CALENDAR_BOOKING, MAKE_INSTANT_BOOKING,
        FETCH_LATEST_INSTANT_BOOKINGS, CANCEL_CALENDAR_BOOKING, RESCHEDULE_BOOKING,
        RESCHEDULE_CALENDAR_BOOKING].includes(action.type)))
        return;

    const allocateRequestTimestamp = () => {
        let requestTimestamp = new Date().toISOString();
        next(setBookingsRequestTimestamp(requestTimestamp));
        return requestTimestamp;
    };

    const triggerAlert = (message, msg_title, type = "warning") => {
        dispatch(setAlert({
            show: true,
            type: type,
            message: message,
            title: msg_title
        }));
    };

    let timestamp;

    switch (action.type) {
        case FETCH_BOOKINGS:
            next(setBookingsLoading(true));
            timestamp = allocateRequestTimestamp();
            next(
                apiRequest({
                    path: "bookings",
                    method: API_GET,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        return setBookings(result, timestamp);
                    },
                    failure: () => setBookingsLoading(false)
                })
            );
            break;
        case FETCH_PRACTITIONER_AVAILABILITY:
            next(setBookingsLoading(true));
            dispatch(clearPractitionerAvailability());
            next(
                apiRequest({
                    full_url: `${API_VCSLOTBOOKING_URL}/practitioners/${action.payload.id}/vc_availability`,
                    method: API_POST,
                    data: {max_days: 14},
                    success: (result) => {
                        next(setBookingsLoading(false));
                        if (!result.hasOwnProperty('error_type')) {
                            let data = result.available_slots.filter(slot => slot.practice_id === action.payload.practice.id);
                            if (data.length === 0) {
                                triggerAlert(
                                    `No booking slot available for ${action.payload.id} in ${action.payload.practice.site.name} - ${action.payload.practice.name}`,
                                    "No booking slot available!"
                                )
                            }
                            return setPractitionerAvailability(data);
                        }
                    },
                    failure: () => {
                        next(setBookingsLoading(false));
                        triggerAlert(
                                    `Failed to retrieve available booking slots for ${action.payload.id} in ${action.payload.practice.site.name} - ${action.payload.practice.name}`,
                                    "Failed to retrieve available booking slots!"
                                )
                    }
                })
            );
            break;
        case CANCEL_CALENDAR_BOOKING:
            next(
                apiRequest({
                    full_url: `${API_VCSLOTBOOKING_URL}/vc_booking/${action.payload}`,
                    method: API_DELETE,
                    data: {},
                    success: (result) => {},
                    failure: (error) => {
                        console.log("error", error)
                    }
                })
            );
            break;
        case RESCHEDULE_BOOKING:
            next(setBookingsLoading(true));
            next(
                apiRequest({
                    path: `booking/reschedule/${action.payload.id}`,
                    method: API_POST,
                    data: action.payload,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        dispatch(setAlert({
                                show: true,
                                type: "success",
                                message: "The booking has been rescheduled",
                                title: "Booking rescheduled!",
                                reload:  true
                            }));
                    },
                    failure: (error) => {
                        next(setBookingsLoading(false));
                        let alert_message = "There was an error rescheduling the booking."
                        if (error?.response?.status === 403){
                            alert_message = error.response?.data?.message
                        }
                        triggerAlert(
                                alert_message,
                                "Booking failed to reschedule!"
                        )
                    }
                })
            );
            break;
        case RESCHEDULE_CALENDAR_BOOKING:
            next(setBookingsLoading(true));
            next(
                apiRequest({
                    full_url: `${API_VCSLOTBOOKING_URL}/vc_booking/${action.payload.id}/reschedule`,
                    method: API_POST,
                    data: action.payload,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        if (!result.hasOwnProperty('error_type')) {
                            dispatch(setAlert({
                                show: true,
                                type: "success",
                                message: "The booking has been rescheduled",
                                title: "Booking rescheduled!",
                                reload:  true
                            }));
                        }
                        else {
                            triggerAlert(
                                "There was an error rescheduling the booking.",
                                "Booking failed to reschedule!"
                            )
                        }
                    },
                    failure: (error) => {
                        next(setBookingsLoading(false));
                        if (error?.response?.status === 403){
                            triggerAlert(
                                error.response?.data?.message,
                                "Booking failed to reschedule!"
                        )
                        }
                        else if (error.response?.data?.error_type === "booking_slot_unavailable"){
                            triggerAlert(
                                "The booking time slot selected is unavailable.",
                                "Booking time slot unavailable!"
                            )
                        }
                        else if (error.response?.data?.error_type === "unauthorised_action"){
                            triggerAlert(
                                "There was an unauthorised error creating the booking.",
                                "Booking failed to create!"
                            )
                        }
                        else {
                            triggerAlert(
                            "There was an error creating the booking.",
                            "Booking failed to create!"
                            )
                        }
                    }
                })
            );
            break;
        case MAKE_CALENDAR_BOOKING:
            next(setBookingsLoading(true));
            next(
                apiRequest({
                    full_url: `${API_VCSLOTBOOKING_URL}/vc_booking`,
                    method: API_POST,
                    data: action.payload,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        if (!result.hasOwnProperty('error_type')) {
                            next(clearBookingsForm());
                            dispatch(setAlert({
                                show: true,
                                type: "success",
                                message: "The booking has been created",
                                title: "Booking created!",
                                confirmAction: action.payload.booking_type === 'group consult facilitator' ? push(`/admin/booking-attachments/${result.booking_id}`) : null
                            }));
                        }
                        else {
                            triggerAlert(
                                "There was an error creating the booking.",
                                "Booking failed to create!"
                            )
                        }
                    },
                    failure: (error) => {
                        next(setBookingsLoading(false));
                        if (error.response?.data?.error_type === "booking_slot_unavailable"){
                            triggerAlert(
                                "The booking time slot selected is unavailable.",
                                "Booking time slot unavailable!"
                            )
                        }
                        else if (error.response?.data?.error_type === "unauthorised_action"){
                            triggerAlert(
                                "There was an unauthorised error creating the booking.",
                                "Booking failed to create!"
                            )
                        }
                        else {
                            triggerAlert(
                            "There was an error creating the booking.",
                            "Booking failed to create!"
                            )
                        }
                    }
                })
            );
            break;
        case FETCH_BOOKING:
            next(setBookingsLoading(true));
            timestamp = allocateRequestTimestamp();
            next(
                apiRequest({
                    path: `group-booking/${action.payload}`,
                    method: API_GET,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        return setBooking(result['bookings'], timestamp);
                    },
                    failure: () => setBookingsLoading(false)
                })
            );
            break;

        case FETCH_CONSULTS:
            next(setBookingsLoading(true));
            timestamp = allocateRequestTimestamp();
            next(
                apiRequest({
                    path: "consults",
                    method: API_GET,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        return setBookings(result, timestamp);
                    },
                    failure: () => setBookingsLoading(false)
                })
            );
            break;
        case PERFORM_BOOKING_ACTION:
            next(
                apiRequest({
                    path: `booking/action/${action.payload.bookingId}`,
                    method: API_POST,
                    data: {
                        action: action.payload.action
                    },
                    success: (result) => {
                        if (action.payload.action === "billing-completed" || action.payload.action === "cancel-booking") {
                            dispatch(fetchBookings());
                        }

                        if (action.payload.action === "resend-booking-practitioner" || action.payload.action === "resend-booking-patient") {
                            const messageTo = action.payload.action === "resend-booking-practitioner" ? "practitioner" : "patient";
                            dispatch(setAlert({
                                show: true,
                                type: "success",
                                message: `Booking has been resent sent to the ${messageTo}`,
                                title: "Booking Resent!"
                            }));
                        }
                    },
                    failure: () => {
                        dispatch(fetchBookings());
                        dispatch(setAlert({
                            show: true,
                            type: "warning",
                            message: "There was an error performing the requested action - please try again or contact support.",
                            title: "Failed to perform action!"
                        }));
                    }
                })
            );
            break;

        case MAKE_BOOKING:
            next(setBookingsLoading(true));
            next(
                apiRequest({
                    path: "booking",
                    method: API_POST,
                    data: action.payload,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        next(clearBookingsForm());
                        dispatch(setAlert({
                            show: true,
                            type: "success",
                            message: "The booking has been created",
                            title: "Booking created!",
                            confirmAction: action.payload.booking_type === 'group consult facilitator' ? push(`/admin/booking-attachments/${result.booking_id}`) : null
                        }));
                    },
                    failure: () => {
                        next(setBookingsLoading(false));
                        dispatch(setAlert({
                            show: true,
                            type: "warning",
                            message: "There was an error creating the booking.",
                            title: "Booking failed to create!"
                        }));
                    }
                })
            );
            break;
        case MAKE_INSTANT_BOOKING:
            next(setBookingsLoading(true));
            next(
                apiRequest({
                    path: "instant_booking",
                    method: API_POST,
                    data: action.payload,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        next(clearBookingsForm());
                        dispatch(fetchLatestInstantBookings());
                        dispatch(setAlert({
                            show: true,
                            type: "success",
                            message: "The booking has been created",
                            title: "Booking created!",
                            confirmAction: action.payload.booking_type === 'group consult facilitator' ? push(`/admin/booking-attachments/${result.booking_id}`) : null
                        }));
                    },
                    failure: () => {
                        next(setBookingsLoading(false));
                        dispatch(setAlert({
                            show: true,
                            type: "warning",
                            message: "There was an error creating the booking.",
                            title: "Booking failed to create!"
                        }));
                    }
                })
            );
            break;
        case FETCH_LATEST_INSTANT_BOOKINGS:
            console.log("FETCH_LATEST_INSTANT_BOOKINGS")
            next(setBookingsLoading(true));
            next(
                apiRequest({
                    path: "latest_instant_bookings",
                    method: API_GET,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        return setInstantBookings(result);
                    },
                    failure: () => setBookingsLoading(false)
                })
            );
            break;
        case FETCH_SURVEY_QUESTIONS:
            next(setBookingsLoading(true));
            next(
                apiRequest({
                    path: `survey-questions/${action.payload}`,
                    method: API_GET,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        return setSurveyQuestions(result['questions']);
                    },
                    failure: () => setBookingsLoading(false)
                })
            );
            break;
        case SAVE_BOOKING_ATTRIBUTES:
            next(setBookingsLoading(true));
            next(
                apiRequest({
                    path: `booking-attributes`,
                    method: API_POST,
                    data: action.payload,
                    success: (result) => {
                        next(setBookingsLoading(false));
                        dispatch(setAlert({
                            show: true,
                            type: "success",
                            message: "The booking attributes have been saved",
                            title: "Booking Attributes Saved!",
                            confirmAction: push(`/admin/edit_booking/${action.payload.booking_id}`)
                        }));
                    },
                    failure: () => {
                        dispatch(setAlert({
                            show: true,
                            type: "warning",
                            message: "An error has occurred - the booking attributes have not been saved. Please try again",
                            title: "Failed to save!"
                        }));
                        next(setBookingsLoading(false));
                    }
                })
            );
            break;
        default:
            break;
    }

};

export default bookings;
